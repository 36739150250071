import axios from 'axios';
import {
  debounce
} from 'debounce';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

class Carousel {
  constructor(options) {

    var defaults = {
      itemsToScroll: 3,
      paginationUrl: null,
      paginationLimit: 25,
      paginationEnd: 3
    };

    this.params = Object.assign({}, defaults, options || {});
    this.container = document.querySelector(this.params.selector);
    this.carousel = this.container.querySelector('.carousel');
    this.carouselItemsContainer = this.container.querySelector('.carousel-items');
    this.carouselItems = this.container.querySelectorAll('.carousel-item');
    this.navigationButtons = this.container.querySelectorAll('.carousel-navigation-button');
    this.previousButton = this.container.querySelector('.carousel-previous');
    this.previousButton.disabled = true;
    this.nextButton = this.container.querySelector('.carousel-next');

    this.paginating = false;
    this.limit = this.params.paginationLimit;
    this.maxPages = this.params.paginationEnd;
    this.start = 0;
    this.page = 1;

    this.addEvents();
  }

  addEvents() {
    for (var index = 0; index < this.navigationButtons.length; index++) {
      this.addNavigationButtonEvent(index);
    }
    if (this.previousButton) {
      this.previousButton.addEventListener('click', (event) => this.navigate(event, 'previous'));
    }
    if (this.nextButton) {
      this.nextButton.addEventListener('click', (event) => this.navigate(event, 'next'));
    }
    if (this.previousButton || this.nextButton) {
      this.carouselItemsContainer.addEventListener('scroll', debounce((event) => this.onScroll(event), 50));
    }

    this.carouselItemsContainer.addEventListener('refresh', (e) => {
      this.refresh();
      this.carouselItemsContainer.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, false);

  }

  addNavigationButtonEvent(index) {
    this.navigationButtons[index].addEventListener('click', (event) => this.navigateTo(event, index));
  }

  onScroll() {
    this.previousButton.disabled = this.carouselItemsContainer.scrollLeft <= 0;
    var endOffset = this.carouselItemsContainer.scrollWidth - this.carouselItemsContainer.clientWidth;
    this.nextButton.disabled = this.carouselItemsContainer.scrollLeft >= endOffset;
    var paginationOffset = endOffset - 800;
    if (this.params.paginationUrl && !this.paginating && this.page <= this.maxPages && this.carouselItemsContainer.scrollLeft >= paginationOffset) {
      this.nextButton.disabled = false;
      this.paginate();
    }
  }

  paginate() {
    this.paginating = true;
    this.carousel.classList.add('paginating');
    this.start += this.limit;
    this.page++;
    axios.get(this.params.paginationUrl + '&start=' + this.start).then((response) => {
      var div = document.createElement('div');
      div.innerHTML = response.data;
      div.classList.add('carousel-items-group');
      this.carouselItemsContainer.appendChild(div);
      this.refresh();
      this.carousel.classList.remove('paginating');
      this.paginating = false;
    });
  }


  refresh() {
    this.carouselItems = this.container.querySelectorAll('.carousel-item');
  }

  navigate(event, direction) {
    var pixels = this.params.itemsToScroll * this.getFirstItemWidth();
    if (direction === 'previous') {
      pixels = -pixels;
    }
    this.navigateBy(event, pixels);
  }

  navigateTo(event, index) {
    event.preventDefault();
    var pixels = index * this.getFirstItemWidth();
    this.carouselItemsContainer.scroll({
      top: 0,
      left: pixels,
      behavior: 'smooth'
    });
  }


  navigateBy(event, pixels) {
    event.preventDefault();
    this.carouselItemsContainer.scrollBy({
      top: 0,
      left: pixels,
      behavior: 'smooth'
    });
  }

  getFirstItemWidth() {
    var style = window.getComputedStyle(this.carouselItems[0]);
    var width = parseInt(style.width) + parseInt(style.marginLeft) + parseInt(style.marginRight);
    return width;
  }
}

export {
  Carousel as
  default
};
