class Global {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.body = document.body;
    this.run();
  }

  run() {
    this.menuToggler();
    this.searchBox();
  }

  menuToggler() {
    var toggler = document.querySelector('.menu-toggle');
    var header = document.querySelector('header');
    if (toggler) {
      toggler.addEventListener('click', function(event) {
        event.preventDefault();
        if (header.classList.contains('menu-expanded')) {
          header.classList.remove('menu-expanded');
        } else {
          header.classList.add('menu-expanded');
        }
      });
    }
  }

  searchBox() {
    var searchBox = document.querySelector('.search-box');
    var searchToggle = document.querySelector('.search-toggle');
    if (searchBox && searchToggle) {
      var input = searchBox.querySelector('input');
      var header = document.querySelector('header');
      searchToggle.addEventListener('click', function(event) {
        event.preventDefault();
        if (header.classList.contains('search-expanded')) {
          header.classList.remove('search-expanded');
        } else {
          header.classList.add('search-expanded');
          input.focus();
        }
      });
    }
  }
}

export {
  Global as
  default
};
